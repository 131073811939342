import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Button as JasperButton } from "jasper-ui"
import styled from "styled-components"

const Styles = styled.div`
  display: inline-block;

  button.yellow {
    background: #fdd262;
    color: black;
  }

  button.yellow.secondary {
    background: transparent;
    color: #fdd262;
    border-color: #fdd262;
  }
`

const Button = (props) => {
  if (props.to) {
    return (
      <Styles className="jasper-button">
        <Link to={props.to} state={props.state}>
          <JasperButton {...props}>{props.children}</JasperButton>
        </Link>
      </Styles>
    )
  }
  if (props.href) {
    const tempProps = Object.assign({}, props)
    delete tempProps.href
    if (props.target) {
      delete tempProps.target
      return (
        <Styles className="jasper-button">
          <a
            href={props.href}
            target={props.target}
            rel={props.target === "_blank" ? "noopener noreferrer" : ""}
          >
            <JasperButton {...tempProps}>{props.children}</JasperButton>
          </a>
        </Styles>
      )
    } else {
      return (
        <Styles className="jasper-button">
          <a href={props.href}>
            <JasperButton {...tempProps}>{props.children}</JasperButton>
          </a>
        </Styles>
      )
    }
  }
  return (
    <Styles className="jasper-button">
      <JasperButton {...props}>{props.children}</JasperButton>
    </Styles>
  )
}

Button.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node,
  state: PropTypes.object,
}
Button.defaultProps = {
  type: "button",
}

export default Button
